var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.isOpen)?_c(_vm.modalWrapperComponent,{tag:"component",attrs:{"to":"modal","slim":""}},[_c('b-modal',_vm._b({attrs:{"active":_vm.isOpen,"has-modal-card":""},on:{"update:active":function($event){_vm.isOpen=$event}}},'b-modal',Object.assign({}, _vm.$attrs),false),[_c('div',{staticClass:"modal-card",class:{
          'has-modal-small': _vm.size === 'small',
          'has-modal-medium': _vm.size === 'medium',
          'has-modal-large': _vm.size === 'large',
        }},[_c('header',{staticClass:"modal-card-head"},[_c('div',{staticClass:"modal-card-title"},[_c('p',{attrs:{"data-test":"modal-title"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._t("modalSubtitle")],2),_c('button',{staticClass:"close-icon",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.isOpen = false}}},[_c('i',{staticClass:"mdi mdi-close"})])]),_c('div',{class:[
            'modal-card-body',
            _vm.modalBodyClass,
            _vm.noFooter ? 'modal-card-body-no-footer' : '',
            _vm.notScrollable ? 'modal-card-body-not-scrollable' : '' ]},[_vm._t("modalBody",null,{"closeModal":function () { return (_vm.isOpen = false); },"isOpen":_vm.isOpen})],2),(!_vm.noFooter)?_c('footer',{staticClass:"modal-card-foot"},[_vm._t("modalFoot",null,{"closeModal":function () { return (_vm.isOpen = false); },"isOpen":_vm.isOpen})],2):_vm._e()])])],1):_vm._e(),_vm._t("openBtn",[_c('button',{staticClass:"button is-primary",on:{"click":function($event){_vm.isOpen = true}}},[_c('i',{staticClass:"mdi mdi-pencil is-block"})])],{"openModal":function () { return (_vm.isOpen = true); }})],2)}
var staticRenderFns = []

export { render, staticRenderFns }