




































import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import Container from '../../components/Container.vue';
import ProfileDetail from './ProfileDetail.vue';
import ProfileDetailsByAdmin from './ProfileDetailsByAdmin.vue';
import PasswordManagement from './PasswordManagement.vue';
import { isDifferent } from '../../jbi-shared/util/watcher.vue-decorator';
import { Action } from 'vuex-class';

/**
 * Declaration of available tabs in the page
 */
const tabNames = ['profile', 'password'];

@Component({
  components: {
    ProfileDetail,
    PasswordManagement,
    ProfileDetailsByAdmin,
    Container
  }
})
export default class UserProfile extends Vue {
  @Action('userAttribute/updateAttributeValueValidity')
  updateAttributeValueValidity!: (isMissingAttributeValue: boolean) => void;

  isAdminUser = false;
  user = null;
  private currentTab = 0;

  get currentRoute() {
    return this.$route.query;
  }

  get userDetails() {
    return this.user;
  }
  public mounted() {
    /**
     * This is here to change the active tab to the default
     * when the user types a URL param that does not exist
     */
    if (this.$route.params && this.$route.params.user) {
      this.isAdminUser = Boolean(this.$route.params.isAdminUser);
      this.user = JSON.parse(this.$route.params.user);
    }
    this.onActiveTabChange(this.currentTab);
  }

  @Watch('currentRoute', { immediate: true, deep: true })
  private onTabChange(query: any) {
    /**
     * Iterate through available tab indexes to find matching values
     */
    const tabIndex = Object.values(tabNames).findIndex(
      (value) => value === query.tab
    );

    this.currentTab = tabIndex !== -1 ? tabIndex : 0;
  }

  @Watch('currentTab')
  @isDifferent
  private onActiveTabChange(value: number): void {
    const isMissingAttributeValue: boolean =
      this.$route.query.missingAttributeValue === 'true';
    if (isMissingAttributeValue) {
      this.updateAttributeValueValidity(isMissingAttributeValue);
    }
    this.$router.replace({ query: { tab: tabNames[value] } });
  }
}
