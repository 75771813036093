
















import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { NotificationProgrammatic as Notification } from 'buefy';
import {
  CroppingRectangle,
  GetDimension,
  ImageEditorState
} from '@/store/types/general.types';

@Component({
  components: {}
})
export default class ImageEditor extends Vue {
  @Prop(String) public image!: string;
  @Prop({ default: 'image/*' }) public accept!: string;
  @Prop({ default: 300 }) public width!: number;
  @Prop({ default: 300 }) public height!: number;
  @Prop({ default: 1 }) public scale!: number;
  @Prop()
  public imageObject!: File[];

  color = [0, 0, 0, 0.5];
  rotation = 0;
  cursor = 'cursorPointer';
  canvas: any = null;
  context: CanvasRenderingContext2D | null = null;
  dragged = false;
  imageLoaded = false;
  changed: boolean = false;
  state: ImageEditorState = {
    drag: false,
    my: null,
    mx: null,
    image: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
      resource: null
    }
  };
  border = 0;
  borderRadius = 0;

  MIN_WIDTH = 150;
  MIN_HEIGHT = 150;

  $refs!: {
    avatarEditorCanvas: HTMLCanvasElement;
  };

  get canvasWidth(): number {
    return this.getDimensions().canvas.width;
  }
  get canvasHeight(): number {
    return this.getDimensions().canvas.height;
  }
  get rotationRadian(): number {
    return (this.rotation * Math.PI) / 180;
  }

  public mounted() {
    this.canvas = this.$refs.avatarEditorCanvas;
    this.context = this.canvas.getContext('2d');
    this.paint();
    const reader = new FileReader();
    this.changed = true;
    reader.onload = (ev: ProgressEvent<FileReader>) => {
      this.loadImage(String(ev.target?.result));
    };
    reader.readAsDataURL(this.imageObject[0]);
  }

  public drawRoundedRect(
    context: CanvasRenderingContext2D,
    x: number,
    y: number,
    width: number,
    height: number,
    borderRadius: number
  ): void {
    if (borderRadius === 0) {
      context.rect(x, y, width, height);
    } else {
      const widthMinusRad = width - borderRadius;
      const heightMinusRad = height - borderRadius;
      context.translate(x, y);
      context.arc(
        borderRadius,
        borderRadius,
        borderRadius,
        Math.PI,
        Math.PI * 1.5
      );
      context.lineTo(widthMinusRad, 0);
      context.arc(
        widthMinusRad,
        borderRadius,
        borderRadius,
        Math.PI * 1.5,
        Math.PI * 2
      );
      context.lineTo(width, heightMinusRad);
      context.arc(
        widthMinusRad,
        heightMinusRad,
        borderRadius,
        Math.PI * 2,
        Math.PI * 0.5
      );
      context.lineTo(borderRadius, height);
      context.arc(
        borderRadius,
        heightMinusRad,
        borderRadius,
        Math.PI * 0.5,
        Math.PI
      );
      context.translate(-x, -y);
    }
  }

  public svgToImage(rawSVG: BlobPart): HTMLImageElement {
    const svg = new Blob([rawSVG], { type: 'image/svg+xml;charset=utf-8' });
    const domURL = self.URL || self.webkitURL || self;
    const url = domURL.createObjectURL(svg);
    const img = new Image();
    img.src = url;
    return img;
  }

  public paint(): void {
    if (!this.context) {
      return;
    }
    this.context.save();
    this.context.translate(0, 0);
    this.context.fillStyle = 'rgba(' + this.color.slice(0, 4).join(',') + ')';
    let borderRadius = this.borderRadius;
    const dimensions = this.getDimensions();
    const borderSize = dimensions.border;
    const height = dimensions.canvas.height;
    const width = dimensions.canvas.width;
    // clamp border radius between zero (perfect rectangle) and half the size without borders (perfect circle or "pill")
    borderRadius = Math.max(borderRadius, 0);
    borderRadius = Math.min(
      borderRadius,
      width / 2 - borderSize,
      height / 2 - borderSize
    );
    this.context.beginPath();
    // inner rect
    this.drawRoundedRect(
      this.context,
      borderSize * 5,
      borderSize * 5,
      300,
      300,
      0
    );
    this.context.rect(width, 0, -width, height); // outer rect, drawn "counterclockwise"
    this.context.fill('evenodd');
    this.context.restore();
  }

  public getDimensions(): GetDimension {
    return {
      width: this.width,
      height: this.height,
      border: this.border,
      canvas: {
        width: this.width + this.border * 2,
        height: this.height + this.border * 2
      }
    };
  }

  public onDragStart(event: Event): void {
    const target = event.target as HTMLInputElement;

    event.preventDefault();
    this.state.drag = true;
    this.state.mx = null;
    this.state.my = null;
    this.cursor = 'cursorGrabbing';
    const eventSubject = document;
    let hasMoved = false;

    const handleMouseUp = (
      ev: MouseEvent | TouchEvent | (TouchEvent & MouseEvent)
    ) => {
      this.onDragEnd(ev);

      if (!hasMoved && ev instanceof TouchEvent && ev.targetTouches) {
        target.click();
      }
      eventSubject.removeEventListener('mouseup', handleMouseUp);
      eventSubject.removeEventListener('mousemove', handleMouseMove);
      eventSubject.removeEventListener('touchend', handleMouseUp);
      eventSubject.removeEventListener('touchmove', handleMouseMove);
    };

    const handleMouseMove = (
      ev: MouseEvent | TouchEvent | (TouchEvent & MouseEvent)
    ) => {
      hasMoved = true;
      const newEvent = ev as TouchEvent & MouseEvent;
      this.onMouseMove(newEvent);
    };
    eventSubject.addEventListener('mouseup', handleMouseUp);
    eventSubject.addEventListener('mousemove', handleMouseMove);
    eventSubject.addEventListener('touchend', handleMouseUp);
    eventSubject.addEventListener('touchmove', handleMouseMove);
  }

  public onDragEnd(e: Event): void {
    if (this.state.drag) {
      this.state.drag = false;
      this.cursor = 'cursorPointer';
    }
  }

  public onMouseMove(event: TouchEvent & MouseEvent): void {
    if (this.state.drag === false) {
      return;
    }
    this.dragged = true;
    this.changed = true;
    const imageState = this.state.image;
    const lastX = imageState.x;
    const lastY = imageState.y;
    const mousePositionX = event.targetTouches
      ? event.targetTouches[0].pageX
      : event.clientX;
    const mousePositionY = event.targetTouches
      ? event.targetTouches[0].pageY
      : event.clientY;
    const newState = {
      mx: mousePositionX,
      my: mousePositionY,
      image: imageState
    };
    if (this.state.mx && this.state.my) {
      const xDiff = (this.state.mx - mousePositionX) / this.scale;

      const yDiff = (this.state.my - mousePositionY) / this.scale;
      imageState.y = this.getBoundedY(lastY - yDiff, this.scale);
      imageState.x = this.getBoundedX(lastX - xDiff, this.scale);
    }
    this.state.mx = newState.mx;
    this.state.my = newState.my;
    this.state.image = imageState;
  }

  public replaceImageInBounds(): void {
    const imageState = this.state.image;
    imageState.y = this.getBoundedY(imageState.y, this.scale);
    imageState.x = this.getBoundedX(imageState.x, this.scale);
  }

  public loadImage(imageURL: string): void {
    const imageObj = new Image();

    imageObj.onload = () => {
      const imageState = this.getInitialSize(imageObj.width, imageObj.height);

      if (
        imageObj!.width! < this.MIN_WIDTH ||
        imageObj!.height! < this.MIN_HEIGHT
      ) {
        Notification.open({
          indefinite: true,
          message: `<div style="width:250px">Image resolution must be more than 150 x 150px </div>`,
          position: 'is-top-right',
          type: `is-danger is-light`,
          queue: false
        });

        this.$emit('closeModal');
      }

      this.state.image.x = 0;
      this.state.image.y = 0;

      this.state.image.resource = imageObj;

      this.state.image.width = imageState.width;

      this.state.image.height = imageState.height;
      this.state.drag = false;
      this.$emit('vue-avatar-editor:image-ready', this.scale);
      this.imageLoaded = true;
      this.$emit('imageLoaded', this.imageLoaded);
      this.cursor = 'cursorGrab';
    };
    imageObj.onerror = (err) => {
      Notification.open({
        type: 'is-danger is-light',
        position: 'is-top-right',
        message: `<div style="width:250px">Error Uploading Image. Please try again</div>`,
        indefinite: true,
        queue: false
      });
      this.$emit('closeModal');
      return null;
    };

    if (!this.isDataURL(imageURL)) {
      imageObj.crossOrigin = 'anonymous';
    }
    imageObj.src = imageURL;
  }

  public getInitialSize(
    width: number,
    height: number
  ): Pick<CroppingRectangle, 'width' | 'height'> {
    let newHeight;
    let newWidth;
    const dimensions = this.getDimensions();
    const canvasRatio = dimensions.height / dimensions.width;
    const imageRatio = height / width;
    if (canvasRatio > imageRatio) {
      newHeight = this.getDimensions().height;
      newWidth = width * (newHeight / height);
    } else {
      newWidth = this.getDimensions().width;
      newHeight = height * (newWidth / width);
    }
    return {
      height: newHeight,
      width: newWidth
    };
  }

  public isDataURL(str: string): boolean {
    if (str === null) {
      return false;
    }
    return !!str.match(
      /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+=[a-z\-]+)?)?(;base64)?,[a-z0-9!$&',()*+;=\-._~:@\/?%\s]*\s*$/i
    ); // eslint-disable-line no-useless-escape
  }

  public getBoundedX(x: number, scale: number): number {
    const image = this.state.image;
    const dimensions = this.getDimensions();
    const width =
      Math.abs(image.width * Math.cos(this.rotationRadian)) +
      Math.abs(image.height * Math.sin(this.rotationRadian));
    let widthDiff = Math.floor((width - dimensions.width / scale) / 2);
    widthDiff = Math.max(0, widthDiff);
    return Math.max(-widthDiff, Math.min(x, widthDiff));
  }

  public getBoundedY(y: number, scale: number): number {
    const image = this.state.image;
    const dimensions = this.getDimensions();
    const height =
      Math.abs(image.width * Math.sin(this.rotationRadian)) +
      Math.abs(image.height * Math.cos(this.rotationRadian));
    let heightDiff = Math.floor((height - dimensions.height / scale) / 2);
    heightDiff = Math.max(0, heightDiff);
    return Math.max(-heightDiff, Math.min(y, heightDiff));
  }

  public paintImage(
    context: CanvasRenderingContext2D | null,
    image: Pick<
      ImageEditorState['image'],
      'x' | 'y' | 'width' | 'height' | 'resource'
    >,
    border: number
  ): void {
    if (!!context && image.resource) {
      const position = this.calculatePosition(image, border);
      context.save();
      context.globalCompositeOperation = 'destination-over';
      const dimensions = this.getDimensions();
      context.translate(
        dimensions.canvas.width / 2,
        dimensions.canvas.height / 2
      );
      context.rotate(this.rotationRadian);
      context.translate(
        -dimensions.canvas.width / 2,
        -dimensions.canvas.height / 2
      );
      context.drawImage(
        image.resource,
        position.x,
        position.y,
        position.width,
        position.height
      );
      context.restore();
    }
  }

  public transformDataWithRotation(x: number, y: number): number[] {
    const radian = -this.rotationRadian;
    const rx = x * Math.cos(radian) - y * Math.sin(radian);
    const ry = x * Math.sin(radian) + y * Math.cos(radian);
    return [rx, ry];
  }

  // Pick is only 1 level deep
  public calculatePosition(
    imageState: Pick<
      ImageEditorState['image'],
      'x' | 'y' | 'width' | 'height' | 'resource'
    >,
    border: number
  ): CroppingRectangle {
    const dimensions = this.getDimensions();
    const width = imageState.width * this.scale;
    const height = imageState.height * this.scale;
    const widthDiff = (width - dimensions.width) / 2;
    const heightDiff = (height - dimensions.height) / 2;
    let x = imageState.x * this.scale; // - widthDiff;
    let y = imageState.y * this.scale; // - heightDiff;
    [x, y] = this.transformDataWithRotation(x, y);
    x += border - widthDiff;
    y += border - heightDiff;
    return {
      x,
      y,
      height,
      width
    };
  }
  public redraw(): void {
    if (!this.context) {
      return;
    }
    this.context.clearRect(
      0,
      0,
      this.getDimensions().canvas.width,
      this.getDimensions().canvas.height
    );
    this.paint();
    this.paintImage(this.context, this.state.image, this.border);
  }

  public getImage(): HTMLCanvasElement | null {
    const cropRect = this.getCroppingRect();
    const image = this.state.image;
    if (!image.resource) {
      return null;
    }
    // get actual pixel coordinates
    cropRect.x *= Number(image.resource.width);
    cropRect.y *= Number(image.resource.height);
    cropRect.width *= Number(image.resource.width);
    cropRect.height *= Number(image.resource.height);
    // create a canvas with the correct dimensions
    const canvas = document.createElement('canvas');
    canvas.width = cropRect.width;
    canvas.height = cropRect.height;
    // draw the full-size image at the correct position,
    // the image gets truncated to the size of the canvas.

    canvas
      .getContext('2d')
      ?.drawImage(image.resource, -cropRect.x, -cropRect.y);
    return canvas;
  }

  public getImageScaled(): HTMLCanvasElement {
    const { width, height } = this.getDimensions();
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    // don't paint a border here, as it is the resulting image
    this.paintImage(canvas.getContext('2d'), this.state.image, 0);
    return canvas;
  }

  public imageChanged(): boolean {
    return this.changed;
  }

  public getCroppingRect(): CroppingRectangle {
    const dim = this.getDimensions();
    const frameRect = {
      x: dim.border,
      y: dim.border,
      width: dim.width,
      height: dim.height
    };
    const imageRect = this.calculatePosition(this.state.image, dim.border);
    return {
      x: (frameRect.x - imageRect.x) / imageRect.width,
      y: (frameRect.y - imageRect.y) / imageRect.height,
      width: frameRect.width / imageRect.width,
      height: frameRect.height / imageRect.height
    };
  }

  @Watch('state', { deep: true })
  private handlerWatcher() {
    if (this.imageLoaded) {
      this.redraw();
    }
  }
  @Watch('scale', { deep: true })
  private scaleWatcher() {
    if (this.imageLoaded) {
      this.replaceImageInBounds();
      this.redraw();
    }
  }
  @Watch('rotation', { deep: true })
  private rotationWatcher() {
    if (this.imageLoaded) {
      this.replaceImageInBounds();
      this.redraw();
    }
  }
  @Watch('borderRadius', { deep: true })
  private borderRadiusWatcher() {
    this.redraw();
  }
}
