
























import { ValidationProvider } from 'vee-validate';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {
    ValidationProvider
  }
})
export default class BSelectWithValdation extends Vue {
  @Prop(String) public vid!: string;
  @Prop([Object, String])
  public rules: object | string | undefined;
  @Prop() public value!: string;

  public innerValue: string | null = null;

  @Watch('innerValue')
  public watchInnerValue(newValue: string) {
    this.$emit('input', newValue);
  }

  @Watch('value')
  public watchValue(newValue: string) {
    this.innerValue = newValue;
  }

  public created() {
    if (this.value) {
      return (this.innerValue = this.value);
    }
  }
  public get newInnerValue(): string | null {
    return this.innerValue;
  }

  public set newInnerValue(value: string | null) {
    this.innerValue = value;
  }
}
