



































import { clone } from '@/jbi-shared/util/group-level-attributes.util';
import { CurrentUserAttribute } from '@/store/modules/admin/types/admin.types';
import {
  CurrentUserAttributeByGroup,
  CurrentUserAttributeByGroupBody,
  MemberGroupUserAttributeValue
} from '@/store/modules/admin/types/group-user-attribute.types';
import store, { RootState } from '@/store/store';
import { ValidationObserver, ValidationObserverInstance } from 'vee-validate';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import AttributeValueWithValidation from './AttributeValueWithValidation.vue';

@Component({
  components: {
    ValidationObserver,
    AttributeValueWithValidation
  }
})
export default class UserAttributeForm extends Vue {
  @Action('admin/getCurrentUserAttributes')
  getCurrentUserAttributes!: () => void;

  @State((state: RootState) => state.admin.currentUserAttributes)
  userAttributeWithValues!: CurrentUserAttribute[];

  userAttributeValueData: CurrentUserAttributeByGroup = {};
  lockUserAttributeValueData: CurrentUserAttributeByGroup = {};
  updatedAttributeValueData: MemberGroupUserAttributeValue[] = [];

  get isAdmin(): boolean {
    return store.getters['rbac/isAdmin'];
  }

  get flattenedLockUserAttributeValueData(): CurrentUserAttributeByGroupBody[] {
    return Object.values(this.lockUserAttributeValueData).flat();
  }

  mounted(): void {
    this.getCurrentUserAttributes();
  }

  haveValuesInvalid(): boolean {
    const errors: string[] = (this.$refs
      .validationObs as ValidationObserverInstance).ctx.errors;
    for (const error of Object.values(errors)) {
      if (error.length) {
        return true;
      }
    }
    return false;
  }

  handleValueChange(updatedValue: MemberGroupUserAttributeValue): void {
    const existingDataIndex: number = this.updatedAttributeValueData.findIndex(
      (data) =>
        data.groupId === updatedValue.groupId &&
        data.userAttributeId === updatedValue.userAttributeId
    );
    if (existingDataIndex === -1) {
      this.updatedAttributeValueData.push(updatedValue);
    } else {
      this.updatedAttributeValueData[existingDataIndex].value =
        updatedValue.value;
    }
    this.$emit('userAttributeFormValidity', this.haveValuesInvalid());
    this.$emit('userAttributeFormValues', this.updatedAttributeValueData);
  }

  sortAttributeValueData(
    attributeValueData: CurrentUserAttributeByGroup
  ): CurrentUserAttributeByGroup {
    return Object.keys(attributeValueData)
      .sort()
      .reduce((valueData, key) => {
        valueData[key] = attributeValueData[key];
        return valueData;
      }, {} as CurrentUserAttributeByGroup);
  }

  /**
   * This is a data massaging function to flatten data structure into
   * attribute spec object (slug: attributeValue) with group info for an easier rendering.
   */
  mapCurrentUserAttributeByGroup(
    userAttributeSpecWithValues: CurrentUserAttribute[]
  ): CurrentUserAttributeByGroup {
    const currentUserAttributeByGroup: CurrentUserAttributeByGroup = {};
    let remainingUserAttributeSpecWithValues: CurrentUserAttribute[] = clone(
      userAttributeSpecWithValues
    );
    let currentLevel: number = 1;
    /**
     * Loop through all the nlevel group and map all the group as flat to `subgroups` and
     * parent group as `parentGroup`
     */
    while (currentLevel !== -1 && !(currentLevel > 5)) {
      userAttributeSpecWithValues.forEach((spec) => {
        const nlevel = spec.groupPath.split('.').length;
        const slug = spec.groupUserAttribute.slug;
        if (!currentUserAttributeByGroup[slug]) {
          currentUserAttributeByGroup[slug] = [];
        }
        if (nlevel === currentLevel) {
          currentUserAttributeByGroup[slug].push({
            parentGroup: spec,
            subgroups: userAttributeSpecWithValues.filter(
              (group) =>
                group.groupPath.includes(spec.groupPath + '.') &&
                group.groupUserAttribute.id === spec.groupUserAttribute.id
            )
          });
          /**
           * Filter out all the group that matches the ancestor group path
           */
          remainingUserAttributeSpecWithValues = remainingUserAttributeSpecWithValues.filter(
            (group) =>
              !(
                group.groupPath.includes(spec.groupPath + '.') &&
                group.groupUserAttribute.id === spec.groupUserAttribute.id
              ) &&
              !(
                group.groupId === spec.groupId &&
                group.groupUserAttribute.id === spec.groupUserAttribute.id
              )
          );
        }
      });
      if (!remainingUserAttributeSpecWithValues.length) {
        currentLevel = -1;
        continue;
      }
      userAttributeSpecWithValues = clone(remainingUserAttributeSpecWithValues);
      currentLevel++;
    }
    return currentUserAttributeByGroup;
  }

  @Watch('userAttributeWithValues')
  onUserAttributeWithValuesChange(): void {
    if (this.userAttributeWithValues?.length) {
      /**
       * Filter out all the lock attribute (only when the current user is normal user)
       */
      if (!this.isAdmin) {
        this.lockUserAttributeValueData = this.mapCurrentUserAttributeByGroup(
          this.userAttributeWithValues.filter((data) => data.lockAttribute)
        );
      }
      this.userAttributeValueData = this.mapCurrentUserAttributeByGroup(
        this.isAdmin
          ? this.userAttributeWithValues
          : this.userAttributeWithValues.filter((data) => !data.lockAttribute)
      );
    }

    /**
     * Removing email, first, and last name attributes,
     * so they don't render in this section.
     *
     * Email, first, and last name updates are being handled in
     * ProfileDetail.vue and ProfileDetailsByAdmin.vue together with country field.
     */
    delete this.userAttributeValueData.email;
    delete this.userAttributeValueData['first-name'];
    delete this.userAttributeValueData['last-name'];

    /**
     * The current business requirement needs to sort by
     * user attribute alphabetic order
     */
    this.userAttributeValueData = this.sortAttributeValueData(
      this.userAttributeValueData
    );
    /**
     * Normal user lock attribute will be displayed in different section, so we need
     * separate sorting for the lock user attribute fields.
     */
    if (Object.values(this.lockUserAttributeValueData).length) {
      this.lockUserAttributeValueData = this.sortAttributeValueData(
        this.lockUserAttributeValueData
      );
    }
  }
}
