

























































import { ToastProgrammatic } from 'buefy';
import delay from 'delay';
import { async } from 'rxjs/internal/scheduler/async';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import {
  MyjbiGroupDetail,
  MyjbiGroupMember
} from '../../../jbi-shared/types/myjbi-group.types';
import {
  Group,
  GroupDetail,
  User
} from '../../../store/modules/admin/types/admin.types';
import {
  DeleteGroupMemberRequestPayload,
  UpdateGroupMemberRequestPayload
} from '../../../store/modules/admin/types/group.types';
import { RootState } from '../../../store/store';
import {
  MemberObject,
  userAttributesArrWithValues
} from '../../../utils/group.util';
import SingleMemberModal from '../../AdminViewGroup/components/SingleMemberModal.vue';

@Component({
  components: {},
  name: 'UserGroupList'
})
export default class UserGroupList extends Vue {
  @Prop(Object) public user!: User;
  @Prop(Object) public group!: MyjbiGroupDetail;
  @Prop(Function) public getChildren!: (
    groupDetail: MyjbiGroupDetail
  ) => Group[];
  @Prop() allowedEmailDomains!: string[];
  @Prop() groupIndex!: number;
  public center!: boolean;
  @Prop({ type: Number, default: 1 }) public level!: number;

  @Action('admin/editGroupMember')
  editGroupMember!: (payload: UpdateGroupMemberRequestPayload) => Promise<void>;

  @Action('admin/deleteGroupMember')
  deleteGroupMember!: (
    payload: DeleteGroupMemberRequestPayload
  ) => Promise<void>;

  @Action('admin/getMasterGroupEmailDomains')
  getMasterGroupEmailDomains!: (id: number) => Promise<string[]>;

  get editGroupMemberSuccess() {
    return (this.$store.state as RootState)?.admin?.apiState?.editGroupMember
      .success;
  }

  get deleteGroupMemberSuccess() {
    return (this.$store.state as RootState)?.admin?.apiState?.deleteGroupMember
      .success;
  }

  get masterGroupEmailDomains() {
    return (this.$store.state as RootState).admin.masterGroupEmailDomains;
  }

  hasBaseAttributesOnly(
    attributeSpecs: MyjbiGroupDetail['groupUserAttributeSpecs']
  ): boolean {
    if (attributeSpecs && attributeSpecs.length) {
      const firstName = attributeSpecs.find((groupUserAttributeSpec) => {
        return groupUserAttributeSpec.groupUserAttribute.slug === 'first-name';
      });
      const lastName = attributeSpecs.find((groupUserAttributeSpec) => {
        return groupUserAttributeSpec.groupUserAttribute.slug === 'last-name';
      });
      const email = attributeSpecs.find((groupUserAttributeSpec) => {
        return groupUserAttributeSpec.groupUserAttribute.slug === 'email';
      });

      if (attributeSpecs.length === 3 && firstName && lastName && email) {
        return true;
      }

      return false;
    }
    return false;
  }

  handleEditMember() {
    const userId: number = this.user.userId;
    const memberObj: MemberObject = {
      userId: this.user.userId,
      attributes: {
        ['email']: this.user.email,
        ['first-name']: this.user.firstName,
        ['last-name']: this.user.lastName
      },
      isActiveUser: this.user.status === 'Active' ? true : false,
      username: this.user.username,
      emailVerified: this.user.emailVerified
    };
    this.$buefy.modal.open({
      parent: this,
      component: SingleMemberModal,
      hasModalCard: true,
      trapFocus: true,
      canCancel: false,
      props: {
        attributeSpecs: this.group.attributeSpec,
        member: memberObj,
        isEdit: true,
        allowedEmailDomains: this.masterGroupEmailDomains,
        groupIndex: this.groupIndex,
        groupName: this.group.name,
        groupId: this.group.id,
        hasNoAdditionalAttribute: this.hasBaseAttributesOnly(
          this.group.attributeSpec
        ),
        isProfilePage: true
      },
      events: {
        submit: (currentMemberObj: MemberObject) => {
          try {
            this.editGroupMember({
              groupId: this.group?.id!,
              userId,
              userAttributesArr:
                [currentMemberObj.values] && this.group.attributeSpec
                  ? userAttributesArrWithValues(
                      [currentMemberObj.values],
                      this.group.attributeSpec
                    )
                  : []
            });
          } catch (error: any) {
            ToastProgrammatic.open({
              queue: true,
              type: 'is-danger',
              position: 'is-top',
              message: error?.response?.data?.message || error,
              duration: 5000
            });
          }
        }
      }
    });
  }

  async handleDeleteMember() {
    const email = this.user.email;
    const userId = this.user.userId;
    const dialogElem: Vue = this.$buefy.dialog.confirm({
      title: `Delete ${email}`,
      message: `Deleting this user will also remove them from all subgroup(s) and revoke their access to product(s). This action cannot be undone.`,
      confirmText: 'Cancel',
      onConfirm: () => undefined,
      canCancel: ['button'],
      cancelText: 'Delete',
      onCancel: () => {
        try {
          this.deleteGroupMember({
            groupId: this.group.id,
            userId
          });
        } catch (error: any) {
          ToastProgrammatic.open({
            queue: true,
            type: 'is-danger',
            position: 'is-top',
            message: error?.response?.data?.message || error,
            duration: 5000
          });
        }
      }
    });

    while (!dialogElem.$el?.querySelector) {
      await delay(50);
    }
    const cancelBtn = dialogElem.$el?.querySelector?.(
      `.modal-card > footer > button:first-child`
    );
    cancelBtn?.classList?.add?.(`is-red`);
  }

  get descendants() {
    return this.getChildren(this.group);
  }

  public mounted() {
    this.getMasterGroupEmailDomains(this.group.id);
  }

  @Watch('deleteGroupMemberSuccess')
  public watchdeleteGroupMemberSuccess(success: boolean) {
    if (!success) {
      return;
    }
    this.$emit('deleteSuccess');
  }

  @Watch('editGroupMemberSuccess')
  public watcheditGroupMemberSuccess(success: boolean) {
    if (!success) {
      return;
    }
    this.$emit('editSuccess');
  }
}
