




































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch, Prop } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { ProfileUpdatePayload } from '@/store/modules/profile/profile.state';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import {
  BInputWithValidation,
  BSelectWithValdation,
  EditProfileImageButton
} from './components';
import { AxiosError } from 'axios';
import _get from 'lodash/get';
import BaseLoading from '@/components/base/BaseLoading.vue';
import { Group, User } from '@/store/modules/admin/types/admin.types';
import { RootState } from '../../store/store';
import UserGroupList from '../AdminGroupManagement/components/UserGroupList.vue';
import { ApiState } from '../../store/types/general.types';
import { MyjbiGroupDetail } from '../../jbi-shared/types/myjbi-group.types';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import {
  AddressCountry,
  AddressFilterOption
} from '@/store/modules/admin/types/group-level-attribute.types';
import { isEmail } from '@/jbi-shared/util/validate-email-domains.util';

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BInputWithValidation,
    BSelectWithValdation,
    BaseLoading,
    EditProfileImageButton,
    UserGroupList
  }
})
export default class ProfileDetailsByAdmin extends Vue {
  @Prop() public user?: User;
  @Prop({ type: Number, default: 1 }) public firstLvl!: number;
  public center!: boolean;
  expanded = false;
  isSubmitButtonDisabled: boolean = true;
  public isEditing: boolean = false;
  public userGroupsKey: number = Math.floor(Math.random() * 999);
  $refs!: {
    vueavatar: HTMLCanvasElement & {
      clicked: () => void;
    };
  };

  countryErrorMsg: string = '';

  @Action('admin/updateUserByAdmin')
  public updateUserByAdmin!: (params: {
    user: any;
    userProfile: ProfileUpdatePayload;
  }) => void;

  @State(({ admin }: RootState) => admin.apiState.updateUserByAdmin)
  public updateUserByAdminState!: ApiState;

  @Action('admin/getUserByAdmin')
  public getUserByAdmin!: (userId: number) => Promise<void>;

  @State(({ admin }: RootState) => admin.apiState.getUserByAdmin)
  public getUserByAdminApiState!: ApiState;

  @Action('admin/getUserGroupsByUserId')
  public getUserGroupsByUserId!: (userId: number) => void;

  @State(({ admin }: RootState) => admin.apiState.getUserGroupsByUserId)
  public getUserGroupsByUserIdApiState!: ApiState;

  @State(({ admin }: RootState) => admin.getUserGroups)
  public getUserGroups!: MyjbiGroupDetail[];

  @Action('admin/getCountries')
  getAllCountries!: (addressFilter?: AddressFilterOption) => void;

  @State(({ admin }: RootState) => admin.apiState.getCountries)
  getAllCountriesApiState!: ApiState;

  @State(({ admin }: RootState) => admin.countries)
  allCountries!: AddressCountry[] | undefined;

  get userProfile() {
    return (this.$store.state as RootState).admin.userProfile;
  }

  get userDetails() {
    return this.user;
  }

  public form: ProfileUpdatePayload = {
    firstName: undefined,
    lastName: undefined,
    gender: undefined,
    phoneNumber: undefined,
    organisation: undefined,
    country: undefined,
    specialty: undefined,
    researchgate: undefined,
    shortbio: undefined,
    source: undefined,
    email: undefined,
    profileImageUrl: undefined
  };
  // username is view-only, hence, not part of the form input
  clonedUsername: string = '';

  get profileLoading() {
    return (this.$store.state as RootState).admin.apiState.getUserByAdmin
      .loading;
  }

  get countriesList(): AddressCountry[] {
    if (this.allCountries && this.form.country) {
      return this.allCountries.filter((country) => {
        return country.name
          .toLowerCase()
          .includes((this.form.country as string).toLowerCase());
      });
    }

    if (!this.form.country) {
      return this.allCountries as AddressCountry[];
    }

    return [];
  }

  get genders() {
    return {
      male: 'Male',
      female: 'Female'
    };
  }

  get getProfileImage() {
    return !!this.userProfile?.profileImageUrl ? true : false;
  }

  get getUserGroupDetails() {
    return this.getUserGroups ? this.getUserGroups : [];
  }

  get firstLvlGroups() {
    if (this.getUserGroups) {
      const firstLevelGroupIds: number[] = [];
      return this.getUserGroups.filter((item: MyjbiGroupDetail) => {
        if (item.nlevel === this.firstLvl) {
          firstLevelGroupIds.push(item.id);
          return item;
        }
      });
    } else {
      return [];
    }
  }

  getChildren(group: Group) {
    const path = group.path;
    if (this.getUserGroups) {
      return this.getUserGroups.filter((g) => g.path === path + '.' + g.id);
    } else {
      return [];
    }
  }

  public get initials(): string {
    if (this.form.firstName && this.form.lastName) {
      return (
        this.form.firstName!.charAt(0).toUpperCase() +
        this.form.lastName!.charAt(0).toUpperCase()
      );
    }
    return 'User';
  }

  public mounted() {
    if (this.user && this.user.userId) {
      this.getUserGroupsByUserId(this.user.userId);
      this.getUserByAdmin(this.user.userId);
    }

    if (this.allCountries === undefined) {
      this.getAllCountries();
    }
  }

  updateForm() {
    this.clonedUsername = this.userProfile?.username || '';

    this.form = {
      ...this.form,
      ...this.userProfile
    };
  }

  public async updatedUser() {
    if (this.user && this.user.userId) {
      return await this.getUserByAdmin(this.user.userId);
    }
  }

  public handleUpdate() {
    if (this.user) {
      const userDetails = {
        id: this.user.userId,
        username: this.user.username,
        email: this.form.email
      };
      return this.updateUserByAdmin({
        user: userDetails,
        userProfile: this.form
      });
    }
  }

  public async validateBeforeSubmit() {
    const valid = await this.$validator.validateAll();
    if (!valid) {
      this.$buefy.toast.open({
        message: 'Form is invalid!',
        type: 'is-danger',
        position: 'is-bottom'
      });
      return;
    } else if (valid) {
      if (this.user) {
        const userDetails = {
          id: this.user.userId,
          username: this.user.username,
          email: this.form.email
        };
        await this.updateUserByAdmin({
          user: userDetails,
          userProfile: {
            ...this.form
          }
        });
        return;
      }
    }
  }

  handleEditDeleteMember() {
    if (this.user && this.user.userId) {
      this.getUserGroupsByUserId(this.user.userId);
      this.userGroupsKey += 1;
    }
  }

  assignCountryValue(selectedCountry: AddressCountry | null): void {
    this.form.country = selectedCountry ? selectedCountry.name : '';
  }

  verifyCountryInput(): void {
    if (!this.allCountries) {
      this.countryErrorMsg = '';
      return;
    }

    if (this.form.country) {
      const targetCountry: AddressCountry | undefined = this.allCountries.find(
        (country) =>
          country.name.toLowerCase() ===
          (this.form.country as string).toLowerCase()
      );

      if (!targetCountry) {
        this.countryErrorMsg = 'Invalid country name.';
        return;
      }

      this.form.country = targetCountry.name;
      this.countryErrorMsg = '';
    }
  }

  @isTruthy
  @Watch('getUserByAdminApiState.success')
  getUserByAdminApiStateCallback() {
    this.updateForm();
  }

  @Watch('updateUserByAdminState.error')
  public watchUpdateUserByAdminError(error: AxiosError) {
    if (!error) {
      return;
    }
    this.$buefy.toast.open({
      message: _get(error, 'response.data.message', 'Unknown Error'),
      type: 'is-danger',
      position: 'is-top'
    });
  }

  @Watch('getUserGroupsByUserIdApiState.success')
  getUserGroupsByUserIdApiStateCallback() {
    this.userGroupsKey += 1;
  }

  @Watch('updateUserByAdminState.success')
  @isTruthy
  public watchUpdateUserByAdminSuccess(success: boolean) {
    if (this.userProfile && this.userProfile.id) {
      this.getUserGroupsByUserId(this.userProfile.id);
    }

    this.isEditing = false;
    this.$buefy.toast.open({
      message: 'Profile updated successfully.',
      type: 'is-success',
      position: 'is-top'
    });
  }

  @Watch('isEditing')
  public watchEditingForm(value: boolean, prevValue: boolean) {
    if (value === true && prevValue === false && this.userProfile) {
      this.form.firstName = this.userProfile.firstName;

      this.form.lastName = this.userProfile.lastName;
      this.form.gender = this.userProfile.gender;
      this.form.phoneNumber = this.userProfile.phoneNumber;
      this.form.organisation = this.userProfile.organisation;
      this.form.country = this.userProfile.country;
      this.form.researchgate = this.userProfile.researchgate;
      this.form.shortbio = this.userProfile.shortbio;
      this.form.source = this.userProfile.source;
      this.form.email = this.userProfile.email;
      this.form.profileImageUrl = this.userProfile.profileImageUrl;
    }
  }

  @Watch('form.firstName')
  @Watch('form.lastName')
  @Watch('form.email')
  @Watch('countryErrorMsg')
  public watchFormValues() {
    this.isSubmitButtonDisabled = !(
      !this.countryErrorMsg &&
      this.form.firstName &&
      this.form.lastName &&
      this.form.email &&
      isEmail(this.form.email)
    );
  }
}
