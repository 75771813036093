




















































import Vue from 'vue';
import { RootState } from '@/store/store';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ManagePasswordPayload } from '@/store/modules/profile/types/profile.types';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { BInputWithValidation } from './components';

import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { Action, State, namespace } from 'vuex-class';
import { get as _get } from 'lodash';
import { ToastProgrammatic as Toast } from 'buefy';

const initialForm = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: ''
};

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    BInputWithValidation
  }
})
export default class PasswordManagement extends Vue {
  @Action('profile/updateMyPassword')
  public updateMyPassword!: (payload: ManagePasswordPayload) => void;

  @State((state: RootState) => state.profile.apiState.updateMyPassword.loading)
  public updateMyPasswordLoading!: boolean;

  @State((state: RootState) => state.profile.apiState.updateMyPassword.success)
  public updateMyPasswordSuccess!: boolean;

  @State((state: RootState) => state.profile.apiState.updateMyPassword.error)
  public updateMyPasswordError!: boolean;

  public form: ManagePasswordPayload = {
    ...initialForm
  };

  @Watch('updateMyPasswordError')
  @isDifferent
  @isTruthy
  public watchUpdatePasswordError(error: AxiosError) {
    Toast.open({
      queue: true,
      type: 'is-danger',
      position: 'is-top',
      message: _get(error, 'response.data.message', 'Update Password Failed')
    });
  }

  @Watch('updateMyPasswordSuccess')
  @isDifferent
  @isTruthy
  public watchUpdatePasswordSuccess(success: boolean) {
    this.resetForm();
    // @ts-ignore
    this.$refs.observer.reset();
    Toast.open({
      message: 'Password updated successfully.',
      type: 'is-success',
      position: 'is-top'
    });
  }

  public resetForm() {
    this.form = {
      ...initialForm
    };
  }

  public async handleUpdate() {
    // @ts-ignore
    const isValid = await this.$refs.observer.validate();
    if (isValid) {
      return this.updateMyPassword(this.form);
    }
  }
}
