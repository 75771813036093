




































import { Component, Vue, Prop } from 'vue-property-decorator';
import { ValidationProvider } from 'vee-validate';
import { get as _get, cloneDeep as _cloneDeep } from 'lodash';
import ImageEditor from './ImageEditor.vue';
import dataURLtoFile from '@/utils/base64-file.util';

export interface ImageSize {
  width: number;
  height: number;
}

@Component({
  components: {
    ValidationProvider,
    ImageEditor
  }
})
export default class EditProfileImageForm extends Vue {
  @Prop()
  public imageFiles!: File[];
  public rotation = 0;
  public scale = 1;
  public imageObject: any = null;

  // ref has no type in vue 2
  // need to be in a class
  // https://stackoverflow.com/questions/46505813/vuejs-typescript-this-refs-reffield-value-does-not-exist
  $refs!: {
    vueavatar: HTMLCanvasElement & {
      getImageScaled: () => HTMLCanvasElement;
      clicked: () => void;
    };
  };

  public onSelectFile(files: File[]) {
    this.imageObject = files;
    this.handleSubmit();
  }
  public onImageReady() {
    this.scale = 1;
    this.rotation = 0;
  }

  public handleSubmit() {
    const profileImageBase64 = this.$refs.vueavatar
      .getImageScaled()
      .toDataURL();

    const imageFile = dataURLtoFile(
      profileImageBase64,
      this.imageObject[0].name
    );
    const formData = new FormData();
    formData.append('image', imageFile);

    return { formData, imageFile };
  }

  public closeModal() {
    this.$emit('closeModal');
  }
  public mounted() {
    this.imageObject = this.imageFiles;
  }
}
