



























import { Component, Prop, Vue } from 'vue-property-decorator';
import EditProfileImageForm from './EditProfileImageForm.vue';

@Component({
  components: {
    EditProfileImageForm
  }
})
export default class ProfileImageModal extends Vue {
  @Prop()
  public imageFiles!: File[];
  public imageObject: any = null;

  $refs!: {
    EditProfileImageForm: EditProfileImageForm;
  };

  public mounted() {
    this.imageObject = this.imageFiles;
  }

  public submit() {
    const {
      formData,
      imageFile
    } = this.$refs.EditProfileImageForm.handleSubmit();
    this.$emit('handleUploadImage', formData, imageFile);
    this.$emit('close');
  }

  public closeModal() {
    this.$emit('close');
  }
}
