















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { ValidationObserver } from 'vee-validate';
import BaseModal from '@/jbi-shared/vue-components/BaseModal.vue';
import EditProfileImageForm from './EditProfileImageForm.vue';
import ProfileImageModal from './ProfileImageModal.vue';
import { GetSignedUrlForUploadResponsePayload } from '../../../store/modules/profile/types/profile.types';
import { User } from '../../../store/modules/admin/types/admin.types';

@Component({
  components: {
    BaseModal,
    EditProfileImageForm,
    ValidationObserver,
    ProfileImageModal
  }
})
export default class InviteUserButton extends Vue {
  public isLoading: boolean = false;
  public isSuccess: boolean = false;
  public isFailed: boolean = false;
  public imageFiles: any = null;
  public modalOpen: boolean = false;

  @Prop() public isAdminUser?: boolean;
  @Prop() public user?: User;

  @Action('profile/getProfileImageSignedUrl')
  public getProfileImageSignedUrl!: (
    payload: File
  ) => Promise<GetSignedUrlForUploadResponsePayload>;

  @Action('profile/updateUserProfileImage')
  public updateUserProfileImage!: (payload: string) => Promise<void>;

  @Action('admin/updateUserProfileImageByAdmin')
  public updateUserProfileImageByAdmin!: (params: {
    user: any;
    profileImageUrl: string;
  }) => Promise<void>;

  $refs!: {
    avatarEditorCanvas: HTMLCanvasElement;
    input: HTMLInputElement;
  };

  public async handleUploadImage(imageFormData: File, imageFile: File) {
    try {
      const signedProfileImageUrls = await this.getProfileImageSignedUrl(
        imageFormData
      );

      const {
        signedUrl: signedUrlForUpload,
        storageUrl,
        storageUri
      } = signedProfileImageUrls;

      const formData = new FormData();
      formData.append('file', imageFile as Blob);

      await this.uploadToGcs(imageFile, signedUrlForUpload);

      if (this.isAdminUser && this.user) {
        const userDetails = {
          id: this.user.userId,
          username: this.user.username,
          email: this.user.email
        };
        await this.updateUserProfileImageByAdmin({
          user: userDetails,
          profileImageUrl: storageUrl
        });
        this.$emit('updatedUser');
      } else {
        await this.updateUserProfileImage(storageUrl);

        this.$emit('updatedUser');
      }
    } catch (error) {
      throw error;
    }
  }

  public uploadToGcs(blobOrFile: File | Blob, url: string) {
    return new Promise((resolve, reject) => {
      this.isLoading = true;

      const xhr = new XMLHttpRequest();
      xhr.open('PUT', url, true);

      xhr.onload = (e) => {
        this.isLoading = false;
        this.isSuccess = true;
        return resolve(true);
      };

      xhr.onerror = (error) => {
        this.isFailed = true;
        return reject(error);
      };

      xhr.upload.onprogress = (e) => {
        if (e.lengthComputable) {
          const progress = (e.loaded / e.total) * 100;
        }
      };

      xhr.send(blobOrFile);
    });
  }

  public openImageSelector() {
    this.$refs.input.click();
  }

  public fileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.imageFiles = target.files!;
    if (this.imageFiles.length === 1) {
      this.$buefy.modal.open({
        parent: this,
        component: ProfileImageModal,
        hasModalCard: true,
        trapFocus: true,
        props: {
          imageFiles: this.imageFiles
        },
        events: {
          handleUploadImage: (formData: File, imageFile: File) => {
            this.handleUploadImage(formData, imageFile);
          }
        }
      });
    }
  }
}
