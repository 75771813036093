
































































































































import {
  MyjbiGroupUserAttributeSpec,
  MyjbiGroupUserAttributeValue
} from '@/jbi-shared/types/myjbi-group.types';
import { componentLevelDebouncer } from '@/jbi-shared/util/group-level-attributes.util';
import { CurrentUserAttribute } from '@/store/modules/admin/types/admin.types';
import {
  CurrentUserAttributeByGroupBody,
  MemberGroupUserAttributeValue
} from '@/store/modules/admin/types/group-user-attribute.types';
import {
  isValidGroupUserAttributeValue,
  UserAttributeValueValidationResult
} from '@/utils/group.util';
import ImageUploadComponent from '@/views/AdminCreateGroup/components/UserLevelAttributes/ImageUploadComponent.vue';
import ListDropdownComponent from '@/views/AdminCreateGroup/components/UserLevelAttributes/ListDropdownComponent.vue';
import { ValidationProvider, ValidationProviderInstance } from 'vee-validate';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    ValidationProvider,
    ImageUploadComponent,
    ListDropdownComponent
  },
  name: 'AttributeValueWithValidation'
})
export default class AttributeValueWithValidation extends Vue {
  @Prop(Object) attribute!:
    | CurrentUserAttributeByGroupBody
    | CurrentUserAttribute;
  @Prop(Number) componentKey!: number;
  @Prop(Boolean) isAdmin!: boolean;
  @Prop(Boolean) isFormDirty!: boolean;
  @Prop() updatedParentGroupValue!: string | Date | null;

  parentGroup: CurrentUserAttribute | null = null;
  subgroups: CurrentUserAttribute[] = [];

  initialValue: string | Date | null = null;
  parentValue: string | Date | null = null;

  subgroupExpanded: boolean = false;
  isFieldDirty: boolean = false;

  updatedValue: string | Date | null = null;

  get editorOption() {
    return {
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'link'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }]
        ]
      },
      placeholder: 'Ctrl/Cmd + Shift + V to paste without formatting'
    };
  }

  get attributeType(): string {
    return this.parentGroup!.groupUserAttribute.groupUserAttributeType.type;
  }

  get fieldDirty(): boolean {
    return (this.$refs.fieldProvider as ValidationProviderInstance).flags.dirty;
  }

  set fieldDirty(isDirty: boolean) {
    (this.$refs
      .fieldProvider as ValidationProviderInstance).flags.dirty = isDirty;
  }

  isDisabled(): boolean {
    return !!this.parentGroup?.lockAttribute && !this.isAdmin;
  }

  isLockAttribute(): boolean {
    return !!this.parentGroup?.lockAttribute;
  }

  getImage(imageUrl: string): void {
    this.parentValue = imageUrl;
    this.fieldDirty = true;
  }

  isUploading(status: boolean): void {
    (this.$refs.fieldProvider as ValidationProviderInstance).applyResult({
      errors: status ? ['Uploading image...'] : [],
      valid: !status,
      failedRules: {}
    });
  }

  getListValue(listValues: string): void {
    this.parentValue = listValues;
    this.fieldDirty = true;
  }

  clearDateValue(): void {
    this.parentValue = null;
    this.fieldDirty = true;
  }

  getFieldLabel(groupUserAttribute: CurrentUserAttribute): string {
    return `${groupUserAttribute.groupUserAttribute.name} (${groupUserAttribute.groupName})`;
  }

  isSubgroup(
    groupUserAttribute: CurrentUserAttributeByGroupBody | CurrentUserAttribute
  ): groupUserAttribute is CurrentUserAttribute {
    return !(groupUserAttribute as CurrentUserAttributeByGroupBody).parentGroup;
  }

  isEmail(spec: MyjbiGroupUserAttributeSpec): boolean {
    return spec.groupUserAttribute.name?.toLowerCase() === 'email';
  }

  isFieldEmpty(value: string | Date | null): boolean {
    return value === '' || value === null;
  }

  handleSubgroupAttributeValueChange(
    updatedValue: MemberGroupUserAttributeValue
  ): void {
    this.$emit('valueChange', updatedValue);
  }

  containInvalidAttributeValue(attributes: CurrentUserAttribute[]): boolean {
    for (const attribute of attributes) {
      const result: UserAttributeValueValidationResult = isValidGroupUserAttributeValue(
        attribute,
        attribute.groupUserAttribute.groupUserAttributeValue![0]?.value
      );
      if (!result.isValid) {
        return true;
      }
    }
    return false;
  }

  emitParentValue(): void {
    if (!this.isDisabled()) {
      const result: UserAttributeValueValidationResult = isValidGroupUserAttributeValue(
        this.parentGroup!,
        this.parentValue
      );
      (this.$refs.fieldProvider as ValidationProviderInstance).applyResult({
        errors: result.errorMessage ? [result.errorMessage] : [],
        valid: result.isValid,
        failedRules: {}
      });
      if (this.isFormDirty) {
        if (!result.errorMessage && result.isValid) {
          if (this.attributeType === 'text area') {
            /**
             * Quill setContents() only accept Delta from Quill, so getContents()
             * is used because it returns the existing Delta object from the editor.
             */
            this.updatedValue = (this.$refs
              .textAreaRef as any).$refs.editor.__quill.getContents();
          } else {
            this.updatedValue = this.parentValue;
          }
        }
        this.$emit('valueChange', {
          groupId: this.parentGroup!.groupId,
          userAttributeId: this.parentGroup!.groupUserAttribute.id,
          value: this.parentValue
        });
      }
    }
  }

  @Watch('parentValue')
  onParentValueChange(): void {
    if (this.isFormDirty) {
      this.isFieldDirty = (this.$refs
        .fieldProvider as ValidationProviderInstance).flags.dirty;
      componentLevelDebouncer(500, this.componentKey, this.emitParentValue);
    }
  }

  @Watch('updatedParentGroupValue')
  onUpdatedParentGroupValueChange(): void {
    if (
      !this.isDisabled() &&
      this.isFormDirty &&
      this.isSubgroup(this.attribute) &&
      this.isFieldEmpty(this.initialValue) &&
      !this.isFieldDirty
    ) {
      if (this.attributeType === 'text area') {
        /**
         * Quill will always call focus() on the latest updated text editor field
         * to avoid the cursor being focused to the subgroup attribute field while
         * updating parent group, setContents() is used to set the value.
         */
        (this.$refs.textAreaRef as any).$refs.editor.__quill.setContents(
          this.updatedParentGroupValue
        );
        /**
         * Due to quill editor limitation on causing the field dirty even though
         * the value is updated programatically, this line is to set the field dirty
         * back to false manually.
         */
        this.fieldDirty = false;
        return;
      }
      this.parentValue = this.updatedParentGroupValue;
    }
  }

  @Watch('attribute', { immediate: true })
  onAttributeChange(): void {
    const { parentGroup, subgroups } = this
      .attribute as CurrentUserAttributeByGroupBody;
    const parentValue: MyjbiGroupUserAttributeValue[] | undefined =
      parentGroup?.groupUserAttribute?.groupUserAttributeValue ??
      (this.attribute as CurrentUserAttribute).groupUserAttribute
        .groupUserAttributeValue;
    /**
     * The parentGroup here can be either the master group
     * or a group that contain subgroups
     */
    this.parentGroup = parentGroup ?? this.attribute;
    this.subgroups = subgroups ?? [];
    this.parentValue = parentValue![0]?.value ?? null;

    const { groupUserAttributeType } = this.parentGroup.groupUserAttribute;
    if (groupUserAttributeType.type === 'date') {
      this.parentValue = this.parentValue ? new Date(this.parentValue) : null;
    }
    if (this.containInvalidAttributeValue(this.subgroups)) {
      this.subgroupExpanded = true;
    }
    this.initialValue = this.parentValue;
    /**
     * After parentValue is updated the DOM and Vue Component is not fully
     * constructed yet, so emit the value after all the async
     * DOM / Vue Component micro task are done, else $ref will be undefined.
     */
    queueMicrotask(() => this.emitParentValue());
  }
}
