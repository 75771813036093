







































import { Component, Prop, Vue } from 'vue-property-decorator';
import { GroupWithEmailDomains } from '@/store/modules/profile/profile.state';
import { MyjbiGroupDetail } from '@/jbi-shared/types/myjbi-group.types';

@Component({})
export default class EmailChangeConfirmationModal extends Vue {
  @Prop()
  newEmail!: string;

  @Prop()
  inValidEmailGroupList!: GroupWithEmailDomains[];

  // to-do: change type any from MyjbiGroupDetail
  getGroupTypeFromGroupDetails(group: any): string {
    return group.types.length > 0 ? group.types[0].name : '';
  }

  handleSubmit() {
    this.$emit('submit');
    this.$emit('close');
  }
}
